<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> Product Type
        </CCardHeader>
        <CCardBody>
            <CForm>
              <CInput
                description="Isikan Judul untuk Product Type anda."
                label="Title"
                horizontal
                autocomplete="title"
                v-model="title"
              />
              <label for="description" class="form-control-label">Description</label>
              <ckeditor @ready="onEditorReady" :editor="editor" v-model="description" :config="editorConfig" :value="description"></ckeditor><br>
              <CInput
                placeholder="Isikan Button Text Anda."
                label="Button Text"
                horizontal
                autocomplete="button_text"
                v-model="button_text"
              />
              <CInput
                placeholder="Isikan Button Url Anda."
                label="Button Url"
                horizontal
                autocomplete="button_url"
                v-model="button_url"
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      product_type_id: "",
      title: "",
      description: "",
      button_text: "",
      button_url: "",
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      }
    }
  },
  methods: {
    getParams : function(){
      this.product_type_id = this.$route.params.data.product_type_id;
      this.title = this.$route.params.data.title;
      this.button_text = this.$route.params.data.button_text;
      this.button_url = this.$route.params.data.button_url;
    },
    onEditorReady: function() { 
      this.description = this.$route.params.data.description; 
    },
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = {
          title: this.title,
          description: this.description,
          button_text: this.button_text,
          button_url: this.button_url,
        }
        this.axios.put("/product_type/" + this.product_type_id, form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "Product Type"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
        });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getParams();
  }
}  
</script>
