import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import VueGoodTablePlugin from "vue-good-table"
import { freeSet } from '@coreui/icons'
import VueFilterDateFormat from 'vue-filter-date-format';
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import vSelect from 'vue-select'

import "vue-good-table/dist/vue-good-table.css";
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)
Vue.use( CKEditor );
Vue.use(VueGoodTablePlugin);
Vue.use(SweetModal)
Vue.use(freeSet)
Vue.use(VueFilterDateFormat);
Vue.config.performance = true
Vue.use(VueAxios, axios)
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).locale('id').format('DD/MM/YYYY')
  }
})

axios.defaults.baseURL = 'https://api.nusapratama.co.id/api' 
axios.defaults.headers.common['Accept'] = 'application/json'

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
  methods: {
    isAuthenticate : function(){
      if(localStorage.getItem("Authorization")){
        let conf = { headers : {"Authorization" : "Bearer " + localStorage.getItem("Authorization")} };
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("Authorization")        
      } else {
        this.$store.commit('logout')
      }
    },
  },
  mounted(){
    this.isAuthenticate()
  },
})
