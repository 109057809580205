import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Master
const About = () => import('@/views/master/about/About')
const AboutAdd = () => import('@/views/master/about/AboutAdd')
const AboutEdit = () => import('@/views/master/about/AboutEdit')

const Blog = () => import('@/views/master/blog/Blog')
const BlogAdd = () => import('@/views/master/blog/BlogAdd')
const BlogEdit = () => import('@/views/master/blog/BlogEdit')

const Career = () => import('@/views/master/career/Career')
const CareerAdd = () => import('@/views/master/career/CareerAdd')
const CareerEdit = () => import('@/views/master/career/CareerEdit')

const Certificate = () => import('@/views/master/certificate/Certificate')
const CertificateAdd = () => import('@/views/master/certificate/CertificateAdd')
const CertificateEdit = () => import('@/views/master/certificate/CertificateEdit')

const Distributor = () => import('@/views/master/distributor/Distributor')
const DistributorAdd = () => import('@/views/master/distributor/DistributorAdd')
const DistributorEdit = () => import('@/views/master/distributor/DistributorEdit')

const HomeSlider = () => import('@/views/master/homeslider/HomeSlider')
const HomeSliderAdd = () => import('@/views/master/homeslider/HomeSliderAdd')
const HomeSliderEdit = () => import('@/views/master/homeslider/HomeSliderEdit')

const Message = () => import('@/views/master/message/Message')

const Partner = () => import('@/views/master/partner/Partner')
const PartnerAdd = () => import('@/views/master/partner/PartnerAdd')
const PartnerEdit = () => import('@/views/master/partner/PartnerEdit')

const Product = () => import('@/views/master/product/Product')
const ProductAdd = () => import('@/views/master/product/ProductAdd')
const ProductEdit = () => import('@/views/master/product/ProductEdit')

const ProductType = () => import('@/views/master/producttype/ProductType')
const ProductTypeAdd = () => import('@/views/master/producttype/ProductTypeAdd')
const ProductTypeEdit = () => import('@/views/master/producttype/ProductTypeEdit')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/pages/login') 
  } else {
    next() 
  }
})

export default router

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/master/about',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'master',
          redirect: '/master/about',
          name: 'Master',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'about',
              name: 'About',
              component: About
            },
            {
              path: 'about/add',
              name: 'About Add',
              component: AboutAdd
            },
            {
              path: 'about/edit',
              name: 'About Edit',
              component: AboutEdit
            },
            {
              path: 'blog',
              name: 'Blog',
              component: Blog
            },
            {
              path: 'blog/add',
              name: 'Blog Add',
              component: BlogAdd
            },
            {
              path: 'blog/edit',
              name: 'Blog Edit',
              component: BlogEdit
            },
            {
              path: 'career',
              name: 'Career',
              component: Career
            },
            {
              path: 'career/add',
              name: 'Career Add',
              component: CareerAdd
            },
            {
              path: 'career/edit',
              name: 'Career Edit',
              component: CareerEdit
            },
            {
              path: 'certificate',
              name: 'Certificate',
              component: Certificate
            },
            {
              path: 'certificate/add',
              name: 'Certificate Add',
              component: CertificateAdd
            },
            {
              path: 'certificate/edit',
              name: 'Certificate Edit',
              component: CertificateEdit
            },
            {
              path: 'distributor',
              name: 'Distributor',
              component: Distributor
            },
            {
              path: 'distributor/add',
              name: 'Distributor Add',
              component: DistributorAdd
            },
            {
              path: 'distributor/edit',
              name: 'Distributor Edit',
              component: DistributorEdit
            },
            {
              path: 'homeslider',
              name: 'Home Slider',
              component: HomeSlider
            },
            {
              path: 'homeslider/add',
              name: 'Home Slider Add',
              component: HomeSliderAdd
            },
            {
              path: 'homeslider/edit',
              name: 'Home Slider Edit',
              component: HomeSliderEdit
            },
            {
              path: 'message',
              name: 'Message',
              component: Message
            },
            {
              path: 'partner',
              name: 'Partner',
              component: Partner
            },
            {
              path: 'partner/add',
              name: 'Partner Add',
              component: PartnerAdd
            },
            {
              path: 'partner/edit',
              name: 'Partner Edit',
              component: PartnerEdit
            },
            {
              path: 'product',
              name: 'Product',
              component: Product
            },
            {
              path: 'product/add',
              name: 'Product Add',
              component: ProductAdd
            },
            {
              path: 'product/edit',
              name: 'Product Edit',
              component: ProductEdit
            },
            {
              path: 'producttype',
              name: 'Product Type',
              component: ProductType
            },
            {
              path: 'producttype/add',
              name: 'Product Type Add',
              component: ProductTypeAdd
            },
            {
              path: 'producttype/edit',
              name: 'Product Type Edit',
              component: ProductTypeEdit
            }
          ]
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

