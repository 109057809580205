<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> About
        </CCardHeader>
        <CCardBody>
            <CForm>
              <label for="description" class="form-control-label">Description</label>
              <ckeditor :editor="editor" v-model="description" :config="editorConfig" placeholder="Tuliskan Description untuk About Anda"></ckeditor><br>
              <label for="history" class="form-control-label">History</label>
              <ckeditor :editor="editor" v-model="history" :config="editorConfig" placeholder="Tuliskan History untuk About Anda"></ckeditor><br>
              <label for="visi" class="form-control-label">Visi</label>
              <ckeditor :editor="editor" v-model="visi" :config="editorConfig" placeholder="Tuliskan Visi untuk About Anda"></ckeditor><br>
              <label for="misi" class="form-control-label">Misi</label>
              <ckeditor :editor="editor" v-model="misi" :config="editorConfig" placeholder="Tuliskan Misi untuk About Anda"></ckeditor><br>
              <label for="office_locations" class="form-control-label">Office Locations</label>
              <ckeditor :editor="editor" v-model="office_locations" :config="editorConfig" placeholder="Tuliskan Office Locations untuk About Anda"></ckeditor><br>
              <CInput
                placeholder="Tuliskan Button Url untuk About anda."
                label="Button URL"
                id="button_url" 
                horizontal
                autocomplete="button_url"
              />
              <CInput
                placeholder="Pilih Image Anda."
                label="Image"
                type="file"
                id="image" 
                horizontal
                autocomplete="image"
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      about_id: "",
      description: "",
      button_url: "",
      history: "",
      visi: "",
      misi: "",
      office_locations: "",
      image: "",
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        placeholder: 'Tuliskan Description untuk About Anda!'
      }
    }
  },
  methods: {
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = new FormData();
        form.append("description", this.description);
        form.append("button_url", this.button_url);
        form.append("history", this.history);
        form.append("visi", this.visi);
        form.append("misi", this.misi);
        form.append("office_locations", this.office_locations);
        form.append("image", document.getElementById("image").files[0]);
        this.axios.post("/about", form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "About"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
      });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
  }
}  
</script>
