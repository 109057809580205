<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> Career
        </CCardHeader>
        <CCardBody>
            <CForm>
              <CInput
                description="Isikan Judul untuk Career anda."
                label="Title"
                horizontal
                autocomplete="title"
                v-model="title"
              />    
              <label for="requirement" class="form-control-label">Requirement</label>
              <ckeditor @ready="onEditorReady" :editor="editor" v-model="requirement" :config="editorConfig" :value="requirement"></ckeditor><br>
              <CInput
                description="Isikan Placement Career Anda."
                label="Placement"
                horizontal
                autocomplete="placement"
                v-model="placement"
              />
              <CInput
                description="Isikan Minimum Education Career Anda."
                label="Minimum Education"
                horizontal
                autocomplete="edu_min"
                v-model="edu_min"
              />              
              <CInput
                label="Updated At"
                type="date"
                v-model="updated_at"
                horizontal
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      career_id: "",
      title: "",
      requirement: "",
      placement: "",
      edu_min: "",
      created_at: "",
      updated_at: "",
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      }
    }
  },
  methods: {
    getParams : function(){
      this.career_id = this.$route.params.data.career_id;
      this.title = this.$route.params.data.title;
      this.placement = this.$route.params.data.placement;
      this.edu_min = this.$route.params.data.edu_min;
      this.created_at = this.$route.params.data.created_at;
    },
    onEditorReady: function() { 
      this.requirement = this.$route.params.data.requirement; 
    },
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = {
          title: this.title,
          requirement: this.requirement,
          placement: this.placement,
          edu_min: this.edu_min,
          created_at: this.created_at,
          updated_at: this.updated_at,
        }
        this.axios.put("/career/" + this.career_id, form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "Career"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
      });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getParams();
  }
}  
</script>
