<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="../../public/img/avatars/6.jpg"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data : function(){
    return {
      itemsCount: 42,
      profile: [],
    }
  },
    computed : {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
        nama : function(){ return this.$store.getters.userDetail},
        level : function(){ return this.$store.getters.userDetail.level}
    },
    methods:{
      getData : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      this.axios.get("/profile", conf)
      .then(response => {
          this.profile = response.data.user;
      });
    },
      logout:function(){
                this.$store.commit('logout')
                localStorage.removeItem("Authorization")
                this.$router.push({name: 'Login'})
      },
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getData();
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>