<template>
  <div>
    <CCard>
      <CCardBody>
        <vue-good-table
        :columns="columns"
        :search-options="{
            enabled: true
        }"
        :rows="home_slider">
        <div slot="table-actions" class="mr-2">
            <CButton block color="primary" @click="showAdd">Add Home Slider</CButton>
        </div>
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'bg'">
                <img style="width:100px; height: auto;" :src="'https://api.nusapratama.co.id/uploads/home_slider/' + props.row.bg" />
            </span>
            <span v-else-if="props.column.field == 'description'" v-html="props.row.description">
                {{props.row.description}}
            </span>
            <span v-else-if="props.column.field == 'status'">
                <select class="form-control" name="status" id="status" v-model="props.row.status" @change="changeStatus(props.row)">
                  <option value="0">Inaktif</option>
                  <option value="1">Aktif</option>
                </select>
            </span>
            <span v-else-if="props.column.field == 'created_at'">
                {{props.row.created_at | formatDate}}
            </span>
            <span v-else-if="props.column.field == 'updated_at'">
                {{props.row.updated_at | formatDate}}
            </span>
            <span v-else-if="props.column.field == 'action'">
              <CButton block color="warning" @click="showEdit(props.row)">Edit</CButton>
              <CButton block color="danger" @click="Drop(props.row.home_slider_id)">Delete</CButton>
            </span>
            <span v-else>
                {{props.formattedRow[props.column.field]}}                
            </span>
        </template>
        </vue-good-table>
      </CCardBody>
    </CCard>
        <sweet-modal icon="success" ref="modalSuccess">
            {{message}}
        </sweet-modal>            
        <sweet-modal icon="warning" ref="modalWarning">
            {{message}}
        </sweet-modal>
    
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'

  export default {
    data : function(){
    return {
      search: "",
      home_slider_id: "",
      title: "",
      tagline: "",
      bg: "",
      description: "",
      button_text: "",
      button_url: "",
      status: "",
      created_at: "",
      updated_at: "",
      find: "",
      action: "",
      message: "",
      key: "",
      home_slider: [],
      columns: [
        {
          label: 'Home Slider ID',
          field: 'home_slider_id',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Tagline',
          field: 'tagline',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Button Text',
          field: 'button_text',
        },
        {
          label: 'Button Url',
          field: 'button_url',
        },
        {
          label: 'Background',
          field: 'bg',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
        {
          label: 'Updated At',
          field: 'updated_at',
        },
        {
          label: 'Actions',
          field: 'action'
        },
      ],
      rows: [],
    }
  },
  methods: {
    getData : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      this.axios.get("/home_slider", conf)
      .then(response => {
        if(response.data.success == true){
          this.home_slider = response.data.data.home_slider;
          this.rows = response.data.data.count;
        } else {
          this.message = "Gagal menampilkan data home_slider."
          this.$router.push({name: "Login"})
        }
      })
      .catch(error => {
        this.message = error.response.message
        console.log(error.response);
      });
    },
    showAdd : function(){
      this.$router.push({name: "Home Slider Add"})
    },
    showEdit : function(item){
      this.$router.push({name: "Home Slider Edit", params: { data: item}})
    },
    changeStatus : function(item){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = {
          status: item.status
        }
        this.axios.put("/home_slider/status/" + item.home_slider_id, form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$refs.modalSuccess.open();
          this.$router.push({name: "Home Slider"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
      });
    },
    Drop : function(id){
      let conf = { headers: { "Authorization" : "Bearer " + this.key} };
      if(confirm('Apakah anda yakin ingin menghapus data ini?')){
        this.axios.delete("/home_slider/" + id, conf)
        .then(response => {
            this.getData();
            this.message = response.data.message;
            this.$refs.modalSuccess.open();
        })
        .catch(error => {
          console.log(error);
        });
      }
    },    
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getData();
  }
}  
</script>
