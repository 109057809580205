<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> Career
        </CCardHeader>
        <CCardBody>
            <CForm>
              <CInput
                description="Isikan Judul untuk Career anda."
                label="Title"
                horizontal
                autocomplete="title"
                v-model="title"
              /> 
              <label for="requirement" class="form-control-label">Requirement</label>
              <ckeditor :editor="editor" v-model="requirement" :config="editorConfig" placeholder="Tuliskan Requirement untuk Career Anda"></ckeditor><br>
              <CInput
                description="Isikan Placement Career Anda."
                label="Placement"
                horizontal
                autocomplete="placement"
                v-model="placement"
              />
              <CInput
                description="Isikan Minimum Education Career Anda."
                label="Minimum Education"
                horizontal
                autocomplete="edu_min"
                v-model="edu_min"
              />              
              <CInput
                label="Created At"
                type="date"
                v-model="created_at"
                horizontal
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      career_id: "",
      title: "",
      requirement: "",
      placement: "",
      edu_min: "",
      created_at: "",
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        placeholder: 'Tuliskan Requirement untuk Career Anda!'
      }
    }
  },
  methods: {
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = new FormData();
        form.append("title", this.title);
        form.append("requirement", this.requirement);
        form.append("placement", this.placement);
        form.append("edu_min", this.edu_min);
        form.append("created_at", this.created_at);
        form.append("updated_at", this.created_at);
        this.axios.post("/career", form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "Career"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
      });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
  }
}  
</script>
