<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> Product
        </CCardHeader>
        <CCardBody>
            <CForm>
              <label for="product_type" class="col-form-label">Product Type</label>
              <v-select v-model="product_type_id" @input="getProductType" :options="product_type" :getOptionLabel="product_type => product_type.title" :reduce="product_type => product_type.product_type_id" placeholder="Ketik untuk mencari Product Type"/><br>
              <CInput
                description="Isikan Judul untuk Product anda."
                label="Title"
                horizontal
                autocomplete="title"
                v-model="title"
              />   
              <label for="description" class="form-control-label">Description</label>
              <ckeditor @ready="onEditorReady" :editor="editor" v-model="description" :config="editorConfig" :value="description"></ckeditor><br>
              <CInput
                placeholder="Isikan Button Text Anda."
                label="Button Text"
                horizontal
                autocomplete="button_text"
                v-model="button_text"
              />
              <CInput
                placeholder="Isikan Button Url Anda."
                label="Button Url"
                horizontal
                autocomplete="button_url"
                v-model="button_url"
              />
              <CInput
                placeholder="Pilih Image Anda."
                label="Image"
                type="file"
                id="image" 
                horizontal
                autocomplete="image"
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      product_id: "",
      product_type_id: "",
      title: "",
      description: "",
      button_text: "",
      button_url: "",
      image: "",
      product_type: [],
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      }
    }
  },
  methods: {
    getProductType : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      this.axios.get("/product_type", conf)
      .then(response => {
        if(response.data.success == true){
          this.product_type = response.data.data.product_type;
          this.rows = response.data.data.count;
        } else {
          this.message = "Gagal menampilkan data Product Type."
          this.$router.push({name: "Login"})
        }
      })
      .catch(error => {
        this.message = error.response.message
        console.log(error.response);
      });
    },
    getParams : function(){
      this.product_id = this.$route.params.data.product_id;
      this.product_type_id = this.$route.params.data.product_type_id;
      this.title = this.$route.params.data.title;
      this.button_text = this.$route.params.data.button_text;
      this.button_url = this.$route.params.data.button_url;
      this.image = this.$route.params.data.image;
    },
    onEditorReady: function() { 
      this.description = this.$route.params.data.description; 
    },
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = new FormData();
        form.append("product_type_id", this.product_type_id);
        form.append("title", this.title);
        form.append("description", this.description);
        form.append("button_text", this.button_text);
        form.append("button_url", this.button_url);
        form.append("image", document.getElementById("image").files[0]);
        this.axios.post("/product/" + this.product_id, form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "Product"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
        });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getParams();
    this.getProductType();
  }
}  
</script>
