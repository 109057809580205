export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Master']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'About',
        to: '/master/about',
        icon: 'cil-newspaper'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Blog',
        to: '/master/blog',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Career',
        to: '/master/career',
        icon: 'cil-briefcase'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Certificate',
        to: '/master/certificate',
        icon: 'cil-notes'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Distributor',
        to: '/master/distributor',
        icon: 'cil-truck'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Home Slider',
        to: '/master/homeslider',
        icon: 'cil-image'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Message',
        to: '/master/message',
        icon: 'cil-envelope-closed'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Partner',
        to: '/master/partner',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Product',
        to: '/master/product',
        icon: 'cil-3d'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Product Type',
        to: '/master/producttype',
        icon: 'cil-grid'
      },
    ]
  }
]