<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> Distributor
        </CCardHeader>
        <CCardBody>
            <CForm>
              <CInput
                description="Isikan Judul untuk Distributor anda."
                label="Title"
                horizontal
                autocomplete="title"
                v-model="title"
              />           
              <label for="address" class="form-control-label">Address</label>
              <ckeditor @ready="onEditorReady" :editor="editor" v-model="address" :config="editorConfig" :value="address"></ckeditor><br>
              <CInput
                placeholder="Isikan Phone Anda."
                label="Phone"
                horizontal
                autocomplete="phone"
                v-model="phone"
              />
              <CInput
                placeholder="Isikan Phone Whatsapp Anda."
                label="Phone Whatsapp"
                horizontal
                autocomplete="phone_whatsapp"
                v-model="phone_whatsapp"
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      distributor_id: "",
      title: "",
      address: "",
      phone: "",
      phone_whatsapp: "",
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      }
    }
  },
  methods: {
    getParams : function(){
      this.distributor_id = this.$route.params.data.distributor_id;
      this.title = this.$route.params.data.title;
      this.phone = this.$route.params.data.phone;
      this.phone_whatsapp = this.$route.params.data.phone_whatsapp;
    },
    onEditorReady: function() { 
      this.address = this.$route.params.data.address; 
    },
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
       let form = {
          title: this.title,
          address: this.address,
          phone: this.phone,
          phone_whatsapp: this.phone_whatsapp,
        }
        this.axios.put("/distributor/" + this.distributor_id, form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "Distributor"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
        });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getParams();
  }
}  
</script>
