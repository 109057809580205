<template>
  <div>
    <CCard>
      <CCardBody>
        <vue-good-table
        :columns="columns"
        :search-options="{
            enabled: true
        }"
        :rows="message">
        <div slot="table-actions" class="mr-2">
        </div>
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'created_at'">
                {{props.row.created_at | formatDate}}
            </span>
            <span v-else-if="props.column.field == 'updated_at'">
                {{props.row.updated_at | formatDate}}
            </span>
            <span v-else>
                {{props.formattedRow[props.column.field]}}                
            </span>
        </template>
        </vue-good-table>
        <sweet-modal icon="success" ref="modalSuccess">
            {{message}}
        </sweet-modal>            
        <sweet-modal icon="warning" ref="modalWarning">
            {{message}}
        </sweet-modal>
      </CCardBody>
    </CCard>    
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'

  export default {
    data : function(){
    return {
      DeleteModal : false,
      search: "",
      message_id: "",
      name: "",
      email: "",
      phone: "",
      content: "",
      file: "",
      created_at: "",
      updated_at: "",
      find: "",
      action: "",
      message: "",
      key: "",
      message: [],
      columns: [
        {
          label: 'Message ID',
          field: 'message_id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Phone',
          field: 'content',
        },
        {
          label: 'File',
          field: 'file',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
        {
          label: 'Updated At',
          field: 'updated_at',
        },
      ],
      rows: [],
    }
  },
  methods: {
    getData : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      this.axios.get("/message", conf)
      .then(response => {
        if(response.data.success == true){
          this.message = response.data.data.message;
          this.rows = response.data.data.count;
        } else {
          this.message = "Gagal menampilkan data message."
          this.$router.push({name: "Login"})
        }
      })
      .catch(error => {
        this.message = error.response.message
        console.log(error.response);
      });
    },
    showAdd : function(){
      this.$router.push({name: "Message Add"})
    },
    showEdit : function(item){
      this.$router.push({name: "Message Edit", params: { data: item}})
    },
    changeStatus : function(item){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = {
          status: item.status
        }
        this.axios.put("/message/status/" + item.message_id, form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$refs.modalSuccess.open();
          this.$router.push({name: "Message"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
      });
    },
    Drop : function(id){
      let conf = { headers: { "Authorization" : "Bearer " + this.key} };
      if(confirm('Apakah anda yakin ingin menghapus data ini?')){
        this.axios.delete("/message/" + id, conf)
        .then(response => {
            this.getData();
            this.message = response.data.message;
            this.$refs.modalSuccess.open();
        })
        .catch(error => {
          console.log(error);
        });
      }
    },    
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
    this.getData();
  }
}  
</script>
