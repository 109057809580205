import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
	state: {
  		status: '',
  		token: localStorage.getItem('Authorization') || '',
  		user : {},
      sidebarShow: 'responsive',
      sidebarMinimize: false
	},
	mutations: {
		auth_request(state){
	  	state.status = 'loading'
	  },
	  auth_success(state, token){
		  state.status = 'success'
		  state.token = token
		},
		userDetail(state,user){
			state.user = user
		},
	  auth_error(state){
	   	state.status = 'error'
	  	},
	  logout(state){
	  	state.status = ''
	   	state.token = ''
	  },
    toggleSidebarDesktop (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
      state[variable] = value
    }  
	},
	actions: {
	  login({commit}, user){
	      return new Promise((resolve, reject) => {
	        commit('auth_request')
	        axios.post('login', user)
	        .then(response => {
	          const token = response.data.token
	          localStorage.setItem('Authorization', token)
	          axios.defaults.headers.common['Authorization'] = token
	          commit('auth_success', token)
	        resolve(response)
	        })
	        .catch(err => {
	        commit('auth_error')
	        localStorage.removeItem('Authorization')
	        reject(err)
	      })
	    })
	  },
		logout({ commit }) {
			return new Promise((resolve) => {
				console.log('Logout Store reached');
				commit('logout')
				localStorage.removeItem('Authorization')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		}
	},
	getters : {
	  isLoggedIn: state => !!state.token,
	  authStatus: state => state.status,
	  userDetail: state => state.user
	}
})