<template>
  <div>
    <CCard>
        <CCardHeader>
          <strong>Form</strong> Blog
        </CCardHeader>
        <CCardBody>
            <CForm>
              <CInput
                description="Isikan Judul untuk Blog anda."
                label="Title"
                horizontal
                autocomplete="title"
                v-model="title"
              />      
              <label for="description" class="form-control-label">Description</label>
              <ckeditor :editor="editor" v-model="description" :config="editorConfig" placeholder="Tuliskan Description untuk Blog Anda"></ckeditor><br>
              <CInput
                placeholder="Isikan Button Text Anda."
                label="Button Text"
                horizontal
                autocomplete="button_text"
                v-model="button_text"
              />
              <CInput
                placeholder="Isikan Button Url Anda."
                label="Button Url"
                horizontal
                autocomplete="button_url"
                v-model="button_url"
              />
              <CInput
                placeholder="Pilih Image Anda."
                label="Image"
                type="file"
                id="image" 
                horizontal
                autocomplete="image"
              />
            </CForm>
        </CCardBody>
        <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="Save"><CIcon name="cil-check-circle"/> Submit</CButton>
        </CCardFooter>
            <sweet-modal icon="success" ref="modalSuccess">
              {{message}}
            </sweet-modal>            
            <sweet-modal icon="warning" ref="modalWarning">
              {{message}}
            </sweet-modal>
    </CCard>
  </div>
</template>

<script>    
  import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    data : function(){
    return {
      search: "",
      blog_id: "",
      title: "",
      description: "",
      button_text: "",
      button_url: "",
      image: "",
      find: "",
      action: "",
      message: "",
      key: "",
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        placeholder: 'Tuliskan Description untuk Blog Anda!'
      }
    }
  },
  methods: {
    Save : function(){
      let conf = { headers: { "Authorization" : 'Bearer ' + this.key } };
      let form = new FormData();
        form.append("title", this.title);
        form.append("description", this.description);
        form.append("button_text", this.button_text);
        form.append("button_url", this.button_url);
        form.append("image", document.getElementById("image").files[0]);
        this.axios.post("/blog", form, conf)
        .then(response => {
          this.message = response.data.message;
          this.$router.push({name: "Blog"})
        })
        .catch(error => {
          const err = error.response.data.errors
          this.message = Object.values(err);
          this.$refs.modalWarning.open();
      });
    }
  },
  mounted(){
    this.key = localStorage.getItem("Authorization");
  }
}  
</script>
