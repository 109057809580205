<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="Login">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                    v-model="username"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow> 
    <sweet-modal icon="warning" ref="modalWarning">
      {{message}}
    </sweet-modal>   
    </CContainer>
  </div>
</template>

<script>
export default {
    data() {
      return {
        username: '',
        password: '',
        message: '',
      }
    },
    methods: {
        Login: function(){
            let username = this.username 
            let password = this.password
            this.$store.dispatch('login', { username, password })
            .then(response => {
                this.$router.push('/').catch(() => {});
            })
            .catch(error => {
              if (error.response.data.errors) {                
                const err = error.response.data.errors
                this.message = err;
                this.$refs.modalWarning.open();
              }else{
                const err = error.response.data.message
                this.message = err;
                this.$refs.modalWarning.open();
              }              
            })
        }
    }
}
</script>
